import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Icon from 'react-icons-kit';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';
import ErrorImage from 'common/src/assets/image/404.svg';
import { home } from 'react-icons-kit/entypo/home';
import { ccw } from 'react-icons-kit/entypo/ccw';
import {
  ErrorWrapper,
  ErrorContent,
  ButtonWrapper,
} from './styles/error.style';

const ErrorSec = ({ imageWrapper, title, text, reloadButton, homeButton }) => {
  const pageReload = () => {
    window.location.reload();
  };
  return (
    <ErrorWrapper>
      <ErrorContent>
        <Box {...imageWrapper} className="image-wrapper">
          <Image src={ErrorImage} alt="404" />
        </Box>
        <Box>
          <Heading {...title} content="Strona nie znaleziona!" />
          <Text
            {...text}
            content="Strona do której próbujesz się dostać nie istnieje. Sprawdź adres URL."
          />
          <ButtonWrapper>
            <Button
              {...reloadButton}
              title="Załaduj ponownie"
              icon={<Icon icon={ccw} size={24} />}
              className="domain_search_button"
              onClick={pageReload}
            />
            <Link to="/">
              <Button
                {...homeButton}
                title="Strona główna"
                icon={<Icon icon={home} size={24} />}
                className="domain_search_button"
              />
            </Link>
          </ButtonWrapper>
        </Box>
      </ErrorContent>
    </ErrorWrapper>
  );
};

ErrorSec.propTypes = {
  imageWrapper: PropTypes.object,
};

ErrorSec.defaultProps = {
  imageWrapper: {
    mb: ['40px', '55px'],
  },
  title: {
    fontSize: ['26px', '32px', '38px', '48px'],
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '35px'],
    lineHeight: '1.31',
    textAlign: 'center',
    fontWeight: '600',
  },
  text: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#343d48',
    lineHeight: '2',
    mb: ['30px', '40px', '50px', '60px'],
    textAlign: 'center',
  },
  reloadButton: {
    pl: ['15px', '22px'],
    pr: ['15px', '22px'],
    iconPosition: 'left',
  },
  homeButton: {
    color: '#0f2137',
    pl: ['15px', '22px'],
    pr: ['15px', '22px'],
    mt: ['20px', 0],
    iconPosition: 'left',
    bg: '#e2e7f0',
  },
};

export default ErrorSec;
