import React from 'react';
import SEO from '../components/seo';
import ErrorSec from '../containers/Error';
import Sticky from 'react-stickynode';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/styles/saas.style';
import Navbar from '../containers/Navbar';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ResetCSS />
    <GlobalStyle />
    <ContentWrapper>
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar returnHome={true} />
        </DrawerProvider>
      </Sticky>
      <ErrorSec />
    </ContentWrapper>
  </>
);

export default NotFoundPage;
